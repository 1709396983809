<template>
  <section>
    <v-row>
      <v-col class="d-flex justify-space-between flex-wrap">
        <h1>Resumen de órdenes</h1>

        <v-btn color="success" @click="download">
          <v-icon left>mdi-download</v-icon>
          Descargar
        </v-btn>
      </v-col>
    </v-row>

    <v-card class="mb-4">
      <v-card-title>Filtros</v-card-title>

      <v-card-text>
        <v-row>
          <v-col v-if="cannot('driver')" cols="12" md="6" lg="3">
            <TextField
              v-model="filters.start_date"
              label="Fecha inicio"
              prepend-icon="mdi-calendar"
              rules=""
              type="date"
            />
          </v-col>

          <v-col v-if="cannot('driver')" cols="12" md="6" lg="3">
            <TextField
              v-model="filters.end_date"
              label="Fecha fin"
              prepend-icon="mdi-calendar"
              rules=""
              type="date"
            />
          </v-col>

          <v-col v-if="can('owner', 'office_admin')" cols="12" md="6" lg="3">
            <SelectField
              v-model="filters.service"
              :items="services"
              item-text="name"
              item-value="name"
              label="Familia"
              prepend-icon="mdi-format-list-bulleted"
              rules=""
            />
          </v-col>

          <v-col v-if="can('owner', 'office_admin')" cols="12" md="6" lg="3">
            <SelectField
              v-model="filters.status"
              :items="status"
              item-text="name"
              item-value="name"
              label="Estatus"
              prepend-icon="mdi-format-list-bulleted"
              rules=""
            />
          </v-col>

          <v-col v-if="cannot('customer')" cols="12" md="6" lg="3">
            <v-checkbox v-model="filters.to_deliver_today" label="Para entregar hoy" />
          </v-col>

          <v-col v-if="cannot('customer')" cols="12" md="6" lg="3">
            <v-checkbox v-model="filters.to_deliver_tomorrow" label="Para entregar mañana" />
          </v-col>

          <v-col v-if="cannot('customer')" cols="12" md="6" lg="3">
            <v-checkbox v-model="filters.delayed_orders" label="Pedidos atrasados" />
          </v-col>

          <v-col v-if="cannot('customer')" cols="12" md="6" lg="3">
            <v-checkbox v-model="filters.weekly_orders" label="Pedidos de la semana" />
          </v-col>

          <v-col v-if="cannot('customer')" cols="12" md="6" lg="3">
            <TextField
              v-model="filters.number"
              label="Número de pedido"
              prepend-icon="mdi-calendar"
              rules=""
            />
          </v-col>

          <v-col v-if="cannot('customer')" cols="12" md="6" lg="3">
            <TextField
              v-model="filters.customer"
              label="Cliente"
              prepend-icon="mdi-calendar"
              rules=""
            />
          </v-col>

          <v-col v-if="cannot('customer')" cols="12" md="6" lg="3">
            <TextField
              v-model="filters.postal_code"
              label="CP"
              prepend-icon="mdi-calendar"
              rules=""
            />
          </v-col>

          <v-col v-if="can('owner')" cols="12" md="6" lg="3">
            <SelectField
              v-model="filters.office"
              :items="offices"
              item-text="name"
              item-value="name"
              label="Sucursal"
              prepend-icon="mdi-store"
              rules=""
            />
          </v-col>

          <v-col cols="12" class="d-flex justify-center">
            <v-btn color="primary" @click="list">
              <v-icon left>mdi-magnify</v-icon>
              Filtrar
            </v-btn>

            <v-btn class="ml-2" color="warning" @click="resetFilters">
              <v-icon left>mdi-delete</v-icon>
              Limpiar filtros
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-card>
      <v-card-title>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Buscar"
          clearable
          hide-details
          single-line
        />
      </v-card-title>

      <v-data-table
        ref="table"
        :headers="headers"
        :items="items"
        :items-per-page="options.itemsPerPage"
        :loading="loading"
        :options.sync="options"
        :search="search"
        :server-items-length="total"
      >
        <template v-slot:item.discount="{ value }">
          {{ value | currency }}
        </template>

        <template v-slot:item.total="{ value }">
          {{ value | currency }}
        </template>

        <template v-slot:item.total_office="{ value }">
          {{ value | currency }}
        </template>

        <template v-slot:item.order_average="{ value }">
          {{ value | currency }}
        </template>

        <template v-slot:item.customer="{ item }">
          <router-link
            :to="{ name: 'customer-detail', params: { uuid: item.customer_uuid } }"
            target="_blank"
          >
            {{ item.customer }}
          </router-link>
        </template>

        <template v-slot:item.office="{ item }">
          <router-link
            v-if="can('owner')"
            :to="{ name: 'office-detail', params: { uuid: item.office_uuid } }"
            target="_blank"
          >
            {{ item.office }}
          </router-link>

          <span v-else>{{ item.office }}</span>
        </template>

        <template v-slot:item.driver="{ item }">
          <router-link
            v-if="can('owner', 'office_admin') && item.driver_uuid"
            :to="{ name: 'employee-detail', params: { uuid: item.driver_uuid } }"
            target="_blank"
          >
            {{ item.driver }}
          </router-link>

          <span v-else>{{ item.driver }}</span>
        </template>

        <template v-slot:item.number="{ item }">
          <router-link
            :to="{ name: 'order-detail', params: { uuid: item.order_uuid } }"
            target="_blank"
          >
            {{ item.number }}
          </router-link>
        </template>

        <template v-slot:item.index="{ item }">
          {{ getItemIndex(item) }}
        </template>
      </v-data-table>
    </v-card>
  </section>
</template>

<script>
// API
import OfficeAPI from '@/api/office';
import OrderAPI from '@/api/order';
import ProductAPI from '@/api/product';

// Mixins
import TableMixin from '@/mixins/TableMixin';

// Utils
import { downloadFile } from '@/utils/file';

const initialFilters = () => ({
  start_date: null,
  end_date: null,
  service: null,
  status: null,
  to_deliver_today: null,
  to_deliver_tomorrow: null,
  delayed_orders: null,
  weekly_orders: null,
  number: null,
  customer: null,
  postal_code: null,
  office: null,
});

export default {
  name: 'OperationSummary',

  mixins: [TableMixin],

  data: () => ({
    filters: initialFilters(),
    offices: [],
    services: [],
    status: [],
  }),

  computed: {
    headers() {
      let allowedFields = [];
      const items = [
        { text: '#', value: 'index' },
        { text: 'No. Pedido', value: 'number', width: '150px' },
        { text: 'CP', value: 'postal_code' },
        { text: 'Fecha de registro', value: 'created_at', width: '150px' },
        { text: 'Fecha de la orden', value: 'order_date', width: '150px' },
        { text: 'Fecha programada', value: 'finish_date', width: '150px' },
        { text: 'Fecha real de la visita', value: 'pickup_real_date', width: '150px' },
        { text: 'Fecha último pedido', value: 'last_order_date', width: '150px' },
        { text: 'Cliente', value: 'customer', width: '150px' },
        // { text: 'Descripción', value: 'description', width: '150px' },
        { text: 'Piezas', value: 'total_products' },
        { text: 'Estatus', value: 'status', width: '150px' },
        { text: 'Descuento', value: 'discount', width: '150px' },
        { text: 'Venta', value: 'total', width: '150px' },
        { text: 'Ticket promedio', value: 'order_average', width: '150px' },
        { text: 'Estatus del cobro', value: 'payment_status', width: '150px' },
        { text: 'Fecha real de entrega', value: 'delivery_date', width: '150px' },
        { text: 'RFC cliente', value: 'customer_rfc', width: '150px' },
        { text: 'Aliado', value: 'office', width: '150px' },
        { text: 'Valet', value: 'driver', width: '150px' },
        { text: 'Código de descuento aplicado', value: 'discount_code', width: '150px' },
        { text: 'Total Aliado', value: 'total_office', width: '150px' },
      ];

      if (this.can('driver')) {
        allowedFields = [
          'index',
          'number',
          'postal_code',
          'order_date',
          'finish_date',
          'customer',
          'total_products',
          'status',
          'total',
          'payment_status',
          'delivery_date',
        ];
      }

      if (this.can('customer')) {
        allowedFields = [
          'index',
          'number',
          'order_date',
          'total_products',
          'status',
          'discount',
          'total',
          'payment_status',
          'delivery_date',
          'office',
          'driver',
          'discount_code',
        ];
      }

      if (this.can('office_admin', 'operator')) {
        allowedFields = [
          'index',
          'number',
          'postal_code',
          'order_date',
          'finish_date',
          'pickup_real_date',
          'customer',
          'description',
          'total_products',
          'status',
          'discount',
          'total',
          'payment_status',
          'delivery_date',
          'customer_rfc',
          'driver',
          'discount_code',
        ];
      }

      return this.can('owner') ? items : items.filter(({ value }) => allowedFields.includes(value));
    },
  },

  async created() {
    this.api = new OrderAPI();
    this.officeAPI = new OfficeAPI();
    this.productAPI = new ProductAPI();
    await Promise.allSettled([
      this.list(),
      this.getServices(),
      this.getOperations(),
      this.can('owner') ? this.getOffices() : true,
    ]);
  },

  methods: {
    async list() {
      this.$store.commit('showLoader');
      this.loading = true;
      try {
        const query = {
          ...this.getQuery(),
          ...this.filters,
        };
        this.items = await this.api.orderReport(query);
        this.total = this.items.length;
      } catch (error) {
        console.log(error);
        this.$store.dispatch('notification/notifyError');
      }
      this.loading = false;
      this.$store.commit('hideLoader');
    },

    getItemIndex(item) {
      return this.items.indexOf(item) + 1;
    },

    resetFilters() {
      this.filters = initialFilters();
      this.list();
    },

    download() {
      this.$store.commit('showLoader');
      const formatCurrency = (value) => `$ ${Number(value || 0).toFixed(2)}`;
      const headers = this.headers.map((header) => header.text).join(',');
      let totalDiscounts = 0;
      let totalOrders = 0;
      let totalAverage = 0;
      const items = this.$refs.table.selectableItems.map((item, index) => {
        totalDiscounts += Number(item.discount);
        totalOrders += Number(item.total);
        totalAverage += Number(item.order_average);
        const itemArrayValues = [];

        this.headers.forEach(({ value }) => {
          let itemValue = item[value];
          const currencyValues = ['discount', 'total', 'order_average', 'total_office'];

          if (value === 'index') {
            itemValue = index + 1;
          }

          if (value === 'postal_code') {
            itemValue = `"=""${item.postal_code}"""`;
          }

          if (currencyValues.includes(value)) {
            itemValue = formatCurrency(item[value]);
          }

          itemArrayValues.push(itemValue);
        });

        return itemArrayValues.join(',');
      });
      totalAverage = items.length > 0 ? (totalAverage / items.length).toFixed(2) : 0;
      items.push(
        this.headers.map(({ value }) => {
          if (value === 'index') return 'TOTAL';
          if (value === 'discount') return formatCurrency(totalDiscounts);
          if (value === 'total') return formatCurrency(totalOrders);
          if (value === 'order_average') return formatCurrency(totalAverage);
          if (value === 'total_office') return formatCurrency(totalOrders);

          return '';
        }),
      );

      // Add BOM to enforce UTF-8
      const csv = `\uFEFF${headers}\n${items.join('\n')}`;
      downloadFile(csv, 'Resumen de órdenes.csv');
      this.$store.commit('hideLoader');
    },

    async getServices() {
      this.$store.commit('showLoader');
      try {
        const res = await this.productAPI.services();
        this.services = res.results;
      } catch (error) {
        this.$store.dispatch('notification/notifyError');
      }
      this.$store.commit('hideLoader');
    },

    async getOperations() {
      this.$store.commit('showLoader');
      try {
        this.status = await this.api.operations();
      } catch (error) {
        console.log(error);
        this.$store.dispatch('notification/notifyError');
      }
      this.$store.commit('hideLoader');
    },

    async getOffices() {
      this.$store.commit('showLoader');
      try {
        const res = await this.officeAPI.list();
        this.offices = res.results;
      } catch (error) {
        console.log(error);
        this.$store.dispatch('notification/notifyError');
      }
      this.$store.commit('hideLoader');
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
